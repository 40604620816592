import { LogoutOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps } from 'antd';
import IconDown from 'assets/Icon/IconDown';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { removeAllCookies } from 'utils/cookies';
import LogoImage from 'assets/images/logo.png';

function TCommonHeader() {
  const { t } = useTranslation();
  const navi = useNavigate();

  const handleLogout = () => {
    removeAllCookies();
    navi('/login');
  };

  const items: MenuProps['items'] = [
    {
      label: (
        <Link to="#" onClick={handleLogout}>
          {' '}
          <LogoutOutlined /> {t('menu.label.logout')}
        </Link>
      ),
      key: '0'
    }
  ];
  return (
    <div className="h-full flex justify-end">
      <div className="flex gap-7 h-full items-center">
        <Dropdown menu={{ items }} trigger={['click']} className="h-full cursor-pointer">
          <div onClick={(e) => e.preventDefault()}>
            <div className="h-full flex items-center gap-3">
              <img className="w-10 h-10 border-white border-[2px] rounded-full object-contain" src={LogoImage} />
              <div className="flex flex-col gap-1 text-white">
                <h3 className="text-sm font-medium">Javis Admin</h3>
              </div>
              <div className="pl-4">
                <IconDown />
              </div>
            </div>
          </div>
        </Dropdown>
      </div>
    </div>
  );
}

export default TCommonHeader;
