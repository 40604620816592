import { BREADCRUMB_NAME } from "constants/common";


 const Routers = {
  All: '/*',
  Dashboard: '/',
  Login: '/login',
  NotFound: '/not-found',
  Jobs: '/job-management',
  CreateJob: '/job-management/create',
  DetailJob: '/job-management/detail',
  WorkflowManagement: '/workflow-management',
  Articles: '/articles-management',
  CreateArticle: '/articles-management/create',
  DetailArticle: '/articles-management/detail',
  InfoArticle: '/articles-management/info',
  Candidates:'/candidates-management',
  CreateMeeting:'/candidates-management/create',
};






export const RoutersBreadCrumb= [
  { path: Routers.Dashboard, breadcrumbName: 'Dashboard' },
  { path: Routers.Login, breadcrumbName: 'Login' },
  { path: Routers.NotFound, breadcrumbName: 'NotFound' },
  { path: Routers.Jobs, breadcrumbName: 'menu.label.jobsManagement' },
  { path: `${Routers.DetailJob}/:id`, breadcrumbName: 'page.jobs.titleDetail' },
  { path: Routers.CreateJob, breadcrumbName: 'page.jobs.titleCreate' },
  { path: Routers.Articles, breadcrumbName: 'Articles Management' },
  { path: Routers.Candidates, breadcrumbName: 'Candidates' },
  { path: Routers.CreateMeeting, breadcrumbName: localStorage.getItem(BREADCRUMB_NAME)? String(localStorage.getItem(BREADCRUMB_NAME))  :"" },
  { path: `${Routers.DetailArticle}/:id`, breadcrumbName: 'Detail' },
  { path: Routers.CreateArticle, breadcrumbName: 'Create' },
  { path: `${Routers.InfoArticle}/:id`, breadcrumbName: 'Info' },
  { path: Routers.WorkflowManagement, breadcrumbName: 'common.label.view' }
];

export const PATH_UNUSED_BREADCRUMB = ['/job-management'];

export default Routers;
