import IconPaper from 'assets/Icon/IconPaper'; // Adjust this import based on how IconPaper is exported
import { useTranslation } from 'react-i18next';
import IconNews from 'assets/Icon/IconNews';
import IconCandidatesMenu from 'assets/Icon/IconCandidatesMenu';

export const useMenuList = () => {
  const { t } = useTranslation();

  return [
    {
      label: t('menu.label.jobsManagement'),
      path: '/job-management',
      key: '/job-management',
      icon: <IconPaper />
    },
    {
      label: 'Articles Management',
      path: '/articles-management',
      key: '/articles-management',
      icon: <IconNews />
    },
    {
      label: 'Candidates',
      path: '/candidates-management',
      key: '/candidates-management',
      icon: <IconCandidatesMenu />
    }
  ];
};
