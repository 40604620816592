import { Menu } from 'antd';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { useMenuList } from 'constants/menu';
import { useEffect, useState } from 'react';
import { To, useLocation, useNavigate } from 'react-router-dom';

function TCommonSidebar() {
  const menuList = useMenuList();
  const navi = useNavigate();
  const location = useLocation();
  const [defaultSelectedKeys, setDefautSelectedKeys] = useState<string>(location.pathname);
  const onSelect = (item: MenuItemType) => {
    navi(item.key as To);
  };

  useEffect(() => {
    setDefautSelectedKeys(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <div className="header__logo" />
      <Menu theme="dark" mode="inline" selectedKeys={[defaultSelectedKeys]} onSelect={onSelect} items={menuList} />
    </>
  );
}

export default TCommonSidebar;
