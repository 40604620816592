import { BrowserRouter as Router } from 'react-router-dom';

import routes, { renderRoutes } from 'routers/routes';

function App() {
  return (
    <>
      <Router>{renderRoutes(routes)}</Router>
    </>
  );
}

export default App;
